
import React, { useState } from "react";
import 'react-image-crop/dist/ReactCrop.css';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import watermark from "watermarkjs";
import sha256 from "sha256";

// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

import CardFooter from "components/Card/CardFooter";
import InputAdornment from "@material-ui/core/InputAdornment";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PublicIcon from '@material-ui/icons/Public';
import CustomInputSelect from "components/CustomInputSelect/CustomInputSelect";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Dropzone from 'react-dropzone';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { Link } from "react-router-dom";
import { getUserSession, encyrptSecret, decryptSecret } from "services/UserManagement"
import { useSnackbar } from 'notistack';

import styles from "assets/jss/material-kit-react/views/loginPage";


const useStyles2 = makeStyles(styles);
const useStyles = makeStyles((theme) => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));


export default function DataInputFieldGenerator(props) {
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const classes2 = useStyles2();

  const [user, setUser] = useState(getUserSession())

  //basic details
  const [fieldName, setFieldName] = useState("");
  const [fieldValue, setFieldValue] = useState("");
  const [dataType, setDataType] = useState("");
  const [dataVisibility, setDataVisibility] = useState("");

  //basic details error
  const [fieldNameError, setFieldNameError] = useState(false);
  const [fieldValueError, setFieldValueError] = useState(false);
  const [dataTypeError, setDataTypeError] = useState(false);
  const [dataVisibilityError, setDataVisibilityError] = useState(false);

  const submitData = (event) => {
    event.preventDefault()
    setFieldNameError(fieldName === "" ? true : false)
    setDataTypeError(dataType === "" ? true : false)
    setFieldValueError(fieldValue === "" || fieldValue === 0 || fieldValue  === null? true : false)

    if (fieldName != "" && dataType != "" && fieldValue != "" || fieldValue > 0) {
      props.addData(fieldName, fieldValue, dataType, dataVisibility)
      setFieldName("")
      setFieldValue("")
      setDataType("")
      setDataVisibility("")
      props.setShowInput(false)

    }

  }
  return (
    <Card>
      <CardBody>
        <form onSubmit={submitData}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <CustomInput
                error={fieldNameError}
                labelText="Data Name *"
                id="fieldName"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "text",
                  onChange: function (e) {
                    setFieldName(e.target.value)
                    setFieldNameError(e.target.value === "" ? true : false)
                  }, value: fieldName,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <CustomInputSelect
                error={dataVisibilityError}
                options={<Select
                  value={dataVisibility}
                  onChange={(e) => {
                    setDataVisibility(e.target.value)
                    setDataVisibilityError(e.target.value === "" ? true : false)
                  }}
                  displayEmpty
                  className={classes2.selectEmpty}>
                  <MenuItem value="">
                    <em>Choose Your Data Visibility</em>
                  </MenuItem>
                  <MenuItem value={"PRIVATE"}>Private</MenuItem>
                  <MenuItem value={"PUBLIC"}>Public</MenuItem>
                </Select>}
                labelText="Data Visibility *"
                id="dataVisibility"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "text", required: true
                }} />
            </GridItem>
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <CustomInputSelect
                error={dataTypeError}
                options={<Select
                  value={dataType}
                  onChange={(e) => {
                    setDataType(e.target.value)
                    setDataTypeError(e.target.value === "" ? true : false)
                  }}
                  displayEmpty
                  className={classes2.selectEmpty}>
                  <MenuItem value="">
                    <em>Choose Your DataType</em>
                  </MenuItem>
                  <MenuItem value={"String"}>String</MenuItem>
                  <MenuItem value={"Integer"}>Integer</MenuItem>
                  <MenuItem value={"Image"}>Image</MenuItem>

                </Select>}
                labelText="Data Type *"
                id="dataType"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "text", required: true
                }} />
            </GridItem>

            {dataType != "" && dataType != "Image" && <GridItem xs={12} sm={12} md={12} lg={12}>
              <CustomInput
                error={fieldValueError}
                labelText="Data Value *"
                id="fieldValue"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: dataType === "Integer" ? "number" : "text",
                  onChange: function (e) {
                    setFieldValue(e.target.value)
                    setFieldValueError(e.target.value === "" ? true : false)
                  }, value: fieldValue,
                }}
              />
            </GridItem>}
            {dataType != "" && dataType == "Image" && <GridItem xs={12} sm={12} md={12} lg={12}>
              <Dropzone onDrop={acceptedFiles => {
                const reader = new FileReader()
                reader.addEventListener("load", async () => {
                  setFieldValue(reader.result)
                  setFieldValueError(false);
                });
                reader.readAsDataURL(acceptedFiles[0])
              }}
                multiple={false} accept={[".png", ".gif", ".jpg"]}>
                {({ getRootProps, getInputProps }) => (
                  <Link >
                    <section className={"container"}>
                      <div {...getRootProps({ className: fieldValueError ? 'dropzoneError' : 'dropzone' })}>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop your image file here, or click to select image file</p>
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={8} lg={8}>
                            {fieldValue != "" && <><p>Image Preview</p>
                              <img src={fieldValue} style={{ height: "200px", marginLeft: "20px", marginRight: "20px" }}
                                className={classes2.imgRounded + " " + classes2.imgFluid} alt={"Image Preview"} /></>}
                          </GridItem>
                        </GridContainer>
                      </div>
                    </section>
                  </Link>
                )}
              </Dropzone>
            </GridItem>
            }

            <Grid container justify="center" spacing={2}>
              <Grid item>
                <Button onClick={() => {
                  props.setShowInput(false)
                }}>Cancel</Button>

              </Grid>
              <Grid item>
                <Button variant="contained" color="info" onClick={submitData} >Set</Button>


              </Grid>  </Grid>


            {/* <Button variant="contained" color="info" onClick={submitData} >Set</Button> */}

          </GridContainer>
        </form>


      </CardBody>
    </Card>

  );
}
