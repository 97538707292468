/* eslint-disable */
import React, { useEffect, useState } from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import TokenMorpher from "views/VaultList/Components/TokenMorpher"
import DummyToken from "views/VaultList/Components/DummyToken"
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { getUserSession } from "services/UserManagement";
import { getTokenByIdList, getTokenBalanceFromStellar } from "services/TokenManagement";
// import Paginations from "components/Pagination/Pagination.js";
import Pagination from '@material-ui/lab/Pagination';
// import Grid from '@material-ui/core/Grid';
import { withRouter, Link } from "react-router-dom";
import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
const useStyles = makeStyles(styles);

function VaultList(props) {
  const classes = useStyles();
  const [user, setUser] = useState(getUserSession())
  const [tokens, setTokens] = useState([])
  const [loading, setloading] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(12)
  const [loadingText, setLoadingText] = useState("loading")
  const [placeholder, setPlaceholder] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])

  const [tradable, setTradable] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setloading(true)

      const key = user.accounts[0].publicKey
      setLoadingText("Retrieving Tokens From Stellar")
      let IdList = []
      const balances = await getTokenBalanceFromStellar(key)
      if (balances != null) {
        //console.log(balances)
        balances.forEach((token) => {
          IdList.push({ id: token.assetCode, issuer: token.issuer })
        })
      }
      if (pageCount != IdList.length / limit) {
        setPageCount(Math.ceil(IdList.length / limit))
      }
      // const loadingCount = IdList.length - limit * (Math.ceil(IdList.length / limit) - page)
      // let PlaceholderArray = []
      // for (let i = 0; i < loadingCount; i++) {
      //   PlaceholderArray.push(i)
      // }
      // setPlaceholder(PlaceholderArray)

      setLoadingText("Retrieving MetaData From Niftron")

      let result = await getTokenByIdList(IdList.slice(limit * (page - 1), limit * (page - 1) + limit))
      if (result != null) {
        if (result.data && result.data.length > 0) {
          // let tokens = []
          // result.data.forEach((token) => {
          //   balances.forEach((sToken) => {
          //     if (sToken.assetCode == token.assetCode) {
          //       token.assetCount=sToken.balance
          //       tokens.push(token)
          //     }
          //   })
          // })
          setTokens(result.data)
        }
        setloading(false)
      }
    }
    fetchData()
  }, [page]);
  return (
    <div>
      {loading &&
        <GridContainer justify="center" style={{ minHeight: 500 }}>
          {placeholder.map(i =>
            <GridItem key={i} xs={12} sm={6} md={4} lg={3} className={"loadingCard"}>
              <DummyToken loadingText={loadingText} />
            </GridItem>
          )}
        </GridContainer>
      }
      {!loading && tokens.length > 0 &&
        <GridContainer justify="center" style={{ minHeight: 500 }} >
          {tokens.map((item, index) => (
            item.data ?
              <GridItem key={index} xs={12} sm={6} md={4} lg={3} >
                <Link to={"/token/" + item.data._id}>
                  <TokenMorpher item={item.data} />
                </Link>
              </GridItem> : null
          ))}
        </GridContainer>
      }
      {tokens.length > 0 && <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Pagination count={pageCount} color="info"
            style={{ float: "right" }}
            onChange={(e, number) => {
              //console.log(number)
              setPage(number)
            }} />
        </GridItem>
      </GridContainer>}
      {!loading && tokens.length === 0 && <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12} >

          <Card >
            <CardHeader color="info" className={classes.cardHeader}>
              <h3>Sorry! your vault is empty.</h3>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>}
    </div>
  );
}

export default withRouter(VaultList)