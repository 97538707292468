

import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
import { CardMedia } from '@material-ui/core';

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardToken from "components/Card/CardToken.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";


import { format } from 'timeago.js';

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Badge(props) {
    const classes = useStyles();
    return (
        <Card>
            {/* <CardToken color="grey"> */}
                <div class="container">

                    <CardMedia style={{ height: 200, maxHeight: 200, "background-size": "contain" }} image={"https://cdn.pixabay.com/photo/2015/05/24/06/13/medical-781422_960_720.jpg"} />
                    <div class="overlay2">
                        <div class="text">{props.item.tokenName}</div>
                    </div>
                </div>
            {/* </CardToken> */}
            <CardFooter stats>
                <div className={classes.stats}>

                    <DateRange />
                    {format(props.item.createdAt)}
                </div>
            </CardFooter>
        </Card>


    );
}
