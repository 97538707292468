
import React, { useEffect, useState } from "react";
// @material-ui/core
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
// import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import TokenMorpher from "views/VaultList/Components/TokenMorpher"
import DummyToken from "views/VaultList/Components/DummyToken"
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { getUserSession } from "services/UserManagement";
import { getTransfersByReceiver } from "services/TransferManagement";
// import Paginations from "components/Pagination/Pagination.js";
import Pagination from '@material-ui/lab/Pagination';
import Grid from '@material-ui/core/Grid';
import { withRouter, Link } from "react-router-dom";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { format } from 'timeago.js';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from "components/CustomButtons/Button";
import CardToken from "components/Card/CardToken.js";
import CardFooter from "components/Card/CardFooter.js";
import DateRange from "@material-ui/icons/DateRange";
import { CardMedia } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import axios from "axios";

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dropzone, { useDropzone } from 'react-dropzone';
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import CustomInput from "components/CustomInput/CustomInput.js";


// function createData(tokenName, assetCode, sender, receiver) {
//   return { tokenName, assetCode, sender, receiver };
// }

// const rows = [
//   createData('Certificate 123', "SNNN0NJHJSHS", "Salman", "Jaje"),
// ];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'createdAt', numeric: false, disablePadding: false, label: 'Received At' },
  { id: 'tokenName', numeric: false, disablePadding: false, label: 'Token Name' },
  { id: 'assetCode', numeric: false, disablePadding: false, label: 'Niftron Id' },
  { id: 'sender', numeric: false, disablePadding: false, label: 'Sender' },
  { id: 'receiver', numeric: false, disablePadding: false, label: 'Receiver' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            Inbox
          </Typography>
        )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row(props) {
  const { row } = props;
  const [user, setUser] = useState(getUserSession())

  const [open, setOpen] = React.useState(false);
  const [ipfsData, setIpfsData] = useState("")

  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  //Confirm 
  const [secret, setSecret] = useState("");

  //Confirm error
  const [secretError, SecretKeyError] = useState(false);
  const [fileError, setFileError] = useState(false)

  //hover and show
  const [hoverPassword, setHoverPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [linkStyle, setLinkStyle] = useState({ cursor: 'pointer' });

  const { enqueueSnackbar } = useSnackbar();

  const classes = useRowStyles();
  useEffect(() => {
    async function fetchIpfs() {
      try {
        const res = await axios.get(`https://ipfs.infura.io/ipfs/${row.ipfsHash}`);
        if (res) {
          //console.log(res.data)
          if (Object.keys(res.data).length === 0) {
            setIpfsData("NA")
          } else {
            setIpfsData(JSON.stringify(res.data))
          }
          // setIpfsData(res.data)
        }
      } catch (er) {
        //console.log(er)
      }
    }
    fetchIpfs()
  }, []);
  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleOpen = () => {
    setOpenDialog(true);
  };

  const performAction = async (event) => {
    event.preventDefault()

  };

  return (
    <React.Fragment>
      <Dialog
        maxWidth="sm"
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        scroll={"body"}
      >
        <DialogTitle id="responsive-dialog-title">{"Please Confirm Action"}</DialogTitle>
        <DialogContent dividers>
          <form style={loading ? {
            filter: "blur(1px)",
            "-webkit-filter": "blur(1px)"
          } : null} onSubmit={performAction}>
            <CustomInput
              error={secretError}
              labelText={user.authType === "2" ? "Secret Key *" : "Password *"}
              id="tokenName"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: !showPassword ? "password" : "text",
                endAdornment: (
                  <InputAdornment position="end">
                    {hoverPassword &&
                      <Icon style={linkStyle} onMouseEnter={() => { setHoverPassword(true) }} onMouseLeave={() => { setHoverPassword(false) }}
                        className={classes.inputIconsColor} onClick={() => {
                          setShowPassword(!showPassword)

                        }}>
                        {showPassword ? "visibilityoff" : "visibility"}</Icon>
                    }

                    {!hoverPassword &&
                      <Icon style={linkStyle} className={classes.inputIconsColor}
                        onMouseEnter={() => { setHoverPassword(true) }} onMouseLeave={() => { setHoverPassword(false) }}>
                        lock</Icon>
                    }
                  </InputAdornment>
                ), required: true,
                onChange: function (e) {
                  setSecret(e.target.value)
                  SecretKeyError(e.target.value === "" ? true : false)
                }, value: secret
              }}
            />
            {user.authType === "2" && <>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
              >

                <Grid item xs={3}>
                  or                  </Grid>

              </Grid>

              <Dropzone onDrop={acceptedFiles => {
                const reader = new FileReader()
                reader.addEventListener("load", async () => {
                  // setFile(reader.result)
                  setSecret(JSON.parse(atob(reader.result)).secretKey)
                });
                reader.readAsText(acceptedFiles[0])
              }}
                multiple={false} accept={[".niftron"]}>
                {({ getRootProps, getInputProps }) => (
                  <Link >
                    <section className={"container"}>
                      <div {...getRootProps({ className: fileError ? 'dropzone2Error' : 'dropzone2' })}>
                        <input {...getInputProps()} />
                        <p>Drop or select your niftron credential file</p>

                      </div>
                    </section>
                  </Link>
                )}
              </Dropzone></>
            }
            <Button color={"info"} onClick={performAction}
              type={"submit"} disabled={loading}>Confirm</Button>
          </form>
        </DialogContent>
      </Dialog>

      <TableRow
        hover
        // onClick={() => setOpen(!open)}
        // onClick={(event) => props.handleClick(event, row.name)}
        role="checkbox"
        aria-checked={props.isItemSelected}
        tabIndex={-1}
        key={row._id}
        selected={props.isItemSelected}
      >
        <TableCell padding="checkbox">
          <Checkbox
            onChange={(event) => props.handleClick(event, row._id)}
            checked={props.isItemSelected}
            inputProps={{ 'aria-labelledby': props.labelId }}
          />
        </TableCell>

        <TableCell component="th" scope="row">
          {format(row.createdAt)}
        </TableCell>
        <TableCell component="th" scope="row">{row.tokenName}</TableCell>
        <TableCell component="th" scope="row">{row.assetCode}</TableCell>
        <TableCell component="th" scope="row">{row.senderAlias}</TableCell>
        <TableCell component="th" scope="row">{row.receiverAlias}</TableCell>
        <TableCell component="th" scope="row">{row.status}</TableCell>

        <TableCell>
          <Button color={"success"} onClick={handleOpen} >Accept</Button>
          <Button color={"danger"} onClick={handleOpen} >Reject</Button>
          <Button color={"info"} onClick={() => setOpen(!open)}>{open ? "Hide Details" : "Show Details"}</Button>

          {/* <IconButton aria-label="expand row" size="small" >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton> */}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <GridContainer>
                <GridItem xs={12} sm={6} md={4} lg={4} >
                  <Card>
                    <CardToken color="grey">
                      <CardMedia style={{ height: 345, maxHeight: 345 }} image={row.previewUrl} />
                    </CardToken>
                    <CardFooter stats>
                      <div className={classes.stats}>
                        <DateRange />
                        {format(row.createdAt)}
                      </div>
                    </CardFooter>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={8} lg={8} >
                  <div className={classes.root}>
                    <Card>
                      <CardBody style={{ height: 345, maxHeight: 345 }}>
                        <GridContainer >
                          <GridItem xs={12} sm={12} md={12} lg={12} >
                            Name: {row.tokenName}
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12} lg={12} >
                            Niftron ID: {row.assetCode}
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12} lg={12} >
                            Quantity: {row.assetCount}
                          </GridItem>
                          {/* <GridItem xs={12} sm={12} md={6} lg={6} >
                                    Type: {row.tokenType}
                                </GridItem> */}
                          {/* <GridItem xs={12} sm={12} md={6} lg={6} >
                                    Category: {row.category}
                                </GridItem> */}
                          <GridItem xs={12} sm={12} md={12} lg={12} >
                            Data: {ipfsData}
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </div>

                </GridItem>
              </GridContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable() {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('date');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [count, setCount] = useState(0)
  const [transfers, setTransfers] = React.useState([])
  const [user, setUser] = useState(getUserSession())
  // const [tokens, setTokens] = useState([])
  const [loading, setloading] = useState(false)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = transfers.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);



  useEffect(() => {
    const fetchData = async () => {
      setloading(true)
      const key = user.publicKey
      const result = await getTransfersByReceiver(key, rowsPerPage, page + 1)
      if (result != null) {
        if (result.data && result.data.length > 0) {
          //console.log(result.data)
          setTransfers(result.data)
          setCount(result.count)
        }
      }
      setloading(false)
    }
    fetchData()
  }, [page]);
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        {loading && <LinearProgress style={{ justifySelf: "center" }} />}
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={transfers.length}
            />
            <TableBody>
              {stableSort(transfers, getComparator(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <Row key={row._id} row={row} isItemSelected={isItemSelected}
                      handleClick={handleClick} isItemSelected={isItemSelected} labelId={labelId} />

                    // <TableRow
                    //   hover
                    //   onClick={(event) => handleClick(event, row.name)}
                    //   role="checkbox"
                    //   aria-checked={isItemSelected}
                    //   tabIndex={-1}
                    //   key={row.name}
                    //   selected={isItemSelected}
                    // >
                    //   <TableCell padding="checkbox">
                    //     <Checkbox
                    //       checked={isItemSelected}
                    //       inputProps={{ 'aria-labelledby': labelId }}
                    //     />
                    //   </TableCell>
                    //   <TableCell component="th" id={labelId} scope="row" padding="none">
                    //     {row.name}
                    //   </TableCell>
                    //   <TableCell align="right">{row.calories}</TableCell>
                    //   <TableCell align="right">{row.fat}</TableCell>
                    //   <TableCell align="right">{row.carbs}</TableCell>
                    //   <TableCell align="right">{row.protein}</TableCell>
                    // </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
  );
}


// function InboxList(props) {
//   const classes = useStyles();
//   const [user, setUser] = useState(getUserSession())
//   const [tokens, setTokens] = useState([])
//   const [loading, setloading] = useState(false)
//   const [count, setCount] = useState(0)
//   const [page, setPage] = useState(1)
//   const [limit, setLimit] = useState(8)
//   const [loadingText, setLoadingText] = useState("loading")
//   const [placeholder, setPlaceholder] = useState([0, 1, 2, 3])

//   const [tradable, setTradable] = useState(false)

//   useEffect(() => {
//     const fetchData = async () => {
//       setloading(true)

//       const key = user.accounts[1].publicKey

//       const result = await getTransfersByReceiver(key, limit, page)
//       if (result != null) {
//         if (result.data && result.data.length > 0) {
//           setTokens(result.data)
//           setCount(result.count)
//         }
//         setloading(false)
//       }
//     }
//     fetchData()
//   }, [page]);
//   return (
//     <div>
//       {loading &&
//         <GridContainer justify="center" style={{ minHeight: 500 }}>
//           {placeholder.map(i =>
//             <GridItem key={i} xs={12} sm={6} md={4} lg={3} className={"loadingCard"}>
//               <DummyToken loadingText={loadingText} />
//             </GridItem>
//           )}
//         </GridContainer>
//       }
//       {!loading && tokens.length > 0 &&
//         <GridContainer justify="center" style={{ minHeight: 500 }} >
//           {tokens.map((item, index) => (
//             item.data ?
//               <GridItem key={index} xs={12} sm={6} md={4} lg={3} >
//                 <Link to={"/token/" + item.data._id}>
//                   <TokenMorpher item={item.data} />
//                 </Link>
//               </GridItem> : null
//           ))}
//         </GridContainer>
//       }
//       {tokens.length > 0 && <GridContainer justify="center">
//         <GridItem xs={12} sm={12} md={12}>
//           <Pagination count={count} color="info"
//             style={{ float: "right" }}
//             onChange={(e, number) => {
//               //console.log(number)
//               setPage(number)
//             }} />
//         </GridItem>
//       </GridContainer>}
//       {!loading && tokens.length === 0 && <GridContainer justify="center">
//         <GridItem xs={12} sm={12} md={12} >

//           <Card >
//             <CardHeader color="info" className={classes.cardHeader}>
//               <h3>Sorry! your vault is empty.</h3>
//             </CardHeader>
//           </Card>
//         </GridItem>
//       </GridContainer>}
//     </div>
//   );
// }

// export default withRouter(InboxList)