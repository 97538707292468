
import React, { useState } from "react";
import 'react-image-crop/dist/ReactCrop.css';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import watermark from "watermarkjs";
import sha256 from "sha256";

// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import CardFooter from "components/Card/CardFooter";
import InputAdornment from "@material-ui/core/InputAdornment";
import Delete from '@material-ui/icons/Delete';
import PublicIcon from '@material-ui/icons/Public';
import CustomInputSelect from "components/CustomInputSelect/CustomInputSelect";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Dropzone from 'react-dropzone';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { Link } from "react-router-dom";
import { getUserSession, decryptSecret, encyrptSecret } from "services/UserManagement"
import { addRecord } from "services/TokenManagement"
import DataInputFieldGenerator2 from "components/DataInputFieldGenerator2/DataInputFieldGenerator2";
import { BuildRecordNFTXDR, signXDR } from "services/XDRBuilder"
import { BuildNiftronId } from "services/NiftronBuilder"
import Icon from "@material-ui/core/Icon";

// import imageCompression from 'browser-image-compression';
import IPFS from 'ipfs';
import { useSnackbar } from 'notistack';

import styles from "assets/jss/material-kit-react/views/loginPage";
import { Keypair } from "stellar-sdk";
import Paper from '@material-ui/core/Paper';
import CardToken from "components/Card/CardToken.js";
import { CardMedia } from '@material-ui/core';


const useStyles2 = makeStyles(styles);
const useStyles = makeStyles((theme) => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paperName: {
    padding: theme.spacing(1),
    // textAlign: 'center',
    // color: theme.palette.text.secondary,
  },
  paperValue: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


export default function MintRecordToken(props) {
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const classes2 = useStyles2();

  const [user, setUser] = useState(getUserSession())
  const [activeStep, setActiveStep] = React.useState(0);

  //basic details
  const [tokenName, setTokenName] = useState("");
  const [tokenType, setTokenType] = useState("NFT");
  const [assetRealm, setAssetRealm] = useState(props.realm);
  const [tradable, setTradable] = useState(false);
  const [category, setCategory] = useState(props.category);
  //basic details error
  const [tokenNameError, setTokenNameError] = useState(false);

  //token specific details
  //d-art
  const [price, setPrice] = useState(0);
  const [assetCount, setAssetCount] = useState(1);

  //d-art error
  const [fileError, setFileError] = useState(false)

  //Confirm 
  //d-art
  const [secret, setSecret] = useState("");
  //d-art error
  const [secretError, setSecretKeyError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingMessage, setloadingMessage] = useState("please remain patient");

  const [hoverPassword, setHoverPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [linkStyle, setLinkStyle] = useState({ cursor: 'pointer' });

  const [open, setOpen] = React.useState(false);


  const [fieldContentArray, setFieldContentArray] = useState([])
  const [fieldContent, setFieldContent] = useState({})
  const [showInput, setShowInput] = useState(false)

  const getSteps = () => {
    return ['Basic Details', 'Record Details'];
  }

  const steps = getSteps();
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      props.setStep("CATEGORY")
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };


  const Basic = (event) => {
    event.preventDefault()
    //required check
    setTokenNameError(tokenName === "" ? true : false);

    if (tokenName != "" && tokenType != "" && category != "" && assetRealm != "") {
      handleNext()
    }
  };

  const SpecificArt = (event) => {
    event.preventDefault()
    //required check


    if (fieldContentArray.length > 0) {
      handleNext()
    }
  };

  const AddToIPFS = async (data) => {
    const stringToUse = JSON.stringify(data)
    const node = await IPFS.create({ repo: String(Math.random() + Date.now()) })
    //console.log('IPFS node is ready')
    // const { id, agentVersion, protocolVersion } = await node.id()
    //console.log(id, agentVersion, protocolVersion)
    for await (const { cid } of node.add(stringToUse)) {
      return cid.toString()
    }
    return null
  }
  const submitDart = async (e) => {
    e.preventDefault()

    setLoading(true)
    setOpen(false);

    //console.log(user)
    setloadingMessage("Verifying User Credentials ...")
    let secretKey = "";
    if (user.authType !== "2") {
      secretKey = decryptSecret(user.encryptedSecret, sha256(secret))
    }
    if (user.authType === "2") {
      secretKey = secret.toUpperCase()
    }

    if (!secretKey || secretKey == "" || secretKey == null || secretKey == undefined) {
      enqueueSnackbar("Invalid Credential", { variant: "warning" });
    } else {
      setloadingMessage("Encrypting Private Data ...")
      //encrypt the necessary fields
      let data = fieldContent

      for (var property in data) {
        if (data.hasOwnProperty(property) && data[property].dataVisibility == "PRIVATE") {
          data[property].dataValue = encyrptSecret(data[property].dataValue, secretKey)
          data[property].encryptedBy = user.publicKey

        }
      }
      setloadingMessage("Uploading Data To IPFS ...")

      const IPFSHash = await AddToIPFS(data)

      console.log(IPFSHash)
      setloadingMessage("Building Blockchain XDR ...")
      const creatorKeypair = Keypair.fromSecret(secretKey);
      let IssuerPublicKey = user.publicKey
      if (!tradable) {
        user.accounts.forEach((account) => {
          if (account.accountType === "1") {
            IssuerPublicKey = account.publicKey
          }
        })
      }
      const { xdrs, niftronId } = await BuildRecordNFTXDR(
        tokenName,
        tokenType,
        tradable,
        false,
        false,
        creatorKeypair,
        assetCount, sha256(JSON.stringify(fieldContent)))

      let xdr;
      if (xdrs != null && xdrs.length > 0) {
        await Promise.all(
          xdrs.map(async (item, index, array) => {
            xdrs[index].xdr = await signXDR(item.xdr, creatorKeypair.secret());
          })
        );
        xdr = xdrs[0]?.xdr
      }
      // //console.log(IPFSHash)

      setloadingMessage("Storing Meta Data to Niftron ...")

      const serverRes = await addRecord({
        tokenName: tokenName,
        tokenType: tokenType,
        assetRealm: assetRealm,
        tradable: tradable,
        transferable: false,
        category: category,
        assetCode: niftronId,
        assetIssuer: IssuerPublicKey,
        issuerAlias: user.alias,
        assetCount: assetCount,
        previewUrl: "NA",
        ipfsHash: IPFSHash,
        price: price,
        xdr
      })
      switch (serverRes) {
        case 200: enqueueSnackbar("Token Minted Successfully", { variant: "success" });
          props.setStep("COMPLETED"); break;
        case 201: enqueueSnackbar("Token Name is already taken", { variant: "warning" }); break;
        case 202: enqueueSnackbar("Insufficient Fund in Account", { variant: "warning" }); break;
        case 400: enqueueSnackbar("Token Minting failed", { variant: "error" }); break;
        case null: enqueueSnackbar("Token Minting failed", { variant: "error" }); break;
        default: enqueueSnackbar("Token Minting failed", { variant: "error" });
      }
    }
    setLoading(false)
  }

  const addData = (fieldName, fieldValue, dataType, dataVisibility) => {
    let data = fieldContent
    data[fieldName] = {
      dataValue: fieldValue,
      dataType,
      dataVisibility
    }
    setFieldContent(data)

    var array = []
    for (var property in data) {
      if (data.hasOwnProperty(property)) {
        array.push({
          name: property,
          value: data[property].dataValue,
          type: data[property].dataType,
          visibility: data[property].dataVisibility
        })
      }
    }
    setFieldContentArray(array)
  }

  const removeData = (fieldName) => {
    let data = fieldContent
    delete data[fieldName]
    setFieldContent(data)
    var array = []
    for (var property in data) {
      if (data.hasOwnProperty(property)) {
        array.push({
          name: property,
          value: data[property].dataValue,
          type: data[property].dataType,
          visibility: data[property].dataVisibility
        })
      }
    }
    setFieldContentArray(array)
  }
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              {activeStep === steps.length ? (
                <div>
                  <Typography className={classes.instructions}>Confirm Your Record Details</Typography>
                  <Dialog
                    maxWidth="sm"
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                    scroll={"body"}
                  >
                    <DialogTitle id="responsive-dialog-title">{"Please Confirm Action"}</DialogTitle>
                    <DialogContent dividers>
                      <form style={loading ? {
                        filter: "blur(1px)",
                        "-webkit-filter": "blur(1px)"
                      } : null} onSubmit={submitDart}>
                        <CustomInput
                          error={secretError}
                          labelText={user.authType === "2" ? "Secret Key *" : "Password *"}
                          id="tokenName"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: !showPassword ? "password" : "text",
                            endAdornment: (
                              <InputAdornment position="end">
                                {hoverPassword &&
                                  <Icon style={linkStyle} onMouseEnter={() => { setHoverPassword(true) }} onMouseLeave={() => { setHoverPassword(false) }}
                                    className={classes.inputIconsColor} onClick={() => {
                                      setShowPassword(!showPassword)
                                    }}>
                                    {showPassword ? "visibilityoff" : "visibility"}</Icon>
                                }
                                {!hoverPassword &&
                                  <Icon style={linkStyle} className={classes.inputIconsColor}
                                    onMouseEnter={() => { setHoverPassword(true) }} onMouseLeave={() => { setHoverPassword(false) }}>
                                    lock</Icon>
                                }
                              </InputAdornment>
                            ), required: true,
                            onChange: function (e) {
                              setSecret(e.target.value)
                              setSecretKeyError(e.target.value === "" ? true : false)
                            }, value: secret
                          }}
                        />
                        {user.authType === "2" && <>
                          <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justify="center"
                          >

                            <Grid item xs={3}>
                              or                  </Grid>

                          </Grid>

                          <Dropzone onDrop={acceptedFiles => {
                            const reader = new FileReader()
                            reader.addEventListener("load", async () => {
                              // setFile(reader.result)
                              setSecret(JSON.parse(atob(reader.result)).secretKey)
                            });
                            reader.readAsText(acceptedFiles[0])
                          }}
                            multiple={false} accept={[".niftron"]}>
                            {({ getRootProps, getInputProps }) => (
                              <Link >
                                <section className={"container"}>
                                  <div {...getRootProps({ className: fileError ? 'dropzone2Error' : 'dropzone2' })}>
                                    <input {...getInputProps()} />
                                    <p>Drop or select your niftron credential file</p>

                                  </div>
                                </section>
                              </Link>
                            )}
                          </Dropzone></>
                        }
                        <Grid container className={classes.root} spacing={2}>
                          <Grid item xs={12}>
                            <Grid container justify="center" spacing={2}>
                              <Grid item>
                                <Button color={"info"} onClick={submitDart}
                                  type={"submit"} disabled={loading}>Confirm</Button>
                              </Grid>

                            </Grid>
                          </Grid>
                        </Grid>

                      </form>
                    </DialogContent>
                  </Dialog>
                  <Card>
                    <CardBody>
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={12}>
                          <div className={classes.root}>
                            <Grid container spacing={1}>
                              <Grid container item xs={12} spacing={3}>
                                <Grid item xs={4}>
                                  <Paper className={classes.paperName}> Niftron Id:</Paper>
                                </Grid>
                                <Grid item xs={8}>
                                  <Paper className={classes.paperValue}> {BuildNiftronId(
                                    tokenName, tokenType, tradable, false,
                                    false, sha256(JSON.stringify(fieldContent))).id}</Paper>
                                </Grid>
                              </Grid>
                              <Grid container item xs={12} spacing={3}>
                                <Grid item xs={4}>
                                  <Paper className={classes.paperName}> Token Name:</Paper>
                                </Grid>
                                <Grid item xs={8}>
                                  <Paper className={classes.paperValue}> {tokenName}</Paper>
                                </Grid>
                              </Grid>

                              <Grid container item xs={12} spacing={3}>
                                <Grid item xs={4}>
                                  <Paper className={classes.paperName}> Token Type:</Paper>
                                </Grid>
                                <Grid item xs={8}>
                                  <Paper className={classes.paperValue}>  {tokenType}</Paper>
                                </Grid>
                              </Grid>

                              <Grid container item xs={12} spacing={3}>
                                <Grid item xs={4}>
                                  <Paper className={classes.paperName}> Asset Realm:
                                  </Paper>
                                </Grid>
                                <Grid item xs={8}>
                                  <Paper className={classes.paperValue}> {assetRealm}</Paper>
                                </Grid>
                              </Grid>

                              <Grid container item xs={12} spacing={3}>
                                <Grid item xs={4}>
                                  <Paper className={classes.paperName}> Category:</Paper>
                                </Grid>
                                <Grid item xs={8}>
                                  <Paper className={classes.paperValue}> {category}</Paper>
                                </Grid>
                              </Grid>
                              {fieldContentArray.map((item) => (
                                < Grid container item xs={12} spacing={3} >
                                  <Grid item xs={4}>
                                    <Paper className={classes.paperName}> {item.name}</Paper>
                                  </Grid>
                                  {item.type === "Image" &&
                                    <Grid item xs={8}>
                                      <Paper className={classes.paperValue}> <img src={item.value} style={{ height: "200px" }}
                                        className={classes2.imgRounded + " " + classes2.imgFluid} alt={"Image Preview"} /></Paper>
                                    </Grid>

                                  }
                                  {item.type !== "Image" &&
                                    <Grid item xs={8}>
                                      <Paper className={classes.paperValue}>{item.value} </Paper>
                                    </Grid>

                                  }

                                </Grid>
                              ))
                              }


                            </Grid>
                          </div>
                          <Typography variant="subtitle1">
                            {loading && loadingMessage}
                            {loading && <CircularProgress />}
                          </Typography>

                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                  <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                      <Grid container justify="center" spacing={2}>
                        <Grid item>
                          <Button disabled={loading} onClick={handleReset}>Make a Change</Button>

                        </Grid>
                        <Grid item>
                          <Button disabled={loading} color={"info"} onClick={handleOpen}>{tradable ? "Create and Publish" : "Create"}</Button>

                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Button disabled={loading} onClick={handleReset}>Make a Change</Button>
                  <Button disabled={loading} color={"info"} onClick={handleOpen}>{tradable ? "Create and Publish" : "Create"}</Button> */}
                </div>
              ) : (
                  <div>
                    <Typography className={classes.instructions}>
                      {activeStep === 0 &&
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={12}>
                            <Card >
                              <form className={classes2.form} style={loading ? {
                                filter: "blur(1px)",
                                "-webkit-filter": "blur(1px)"
                              } : null} onSubmit={Basic}>
                                <CardHeader color="info" className={classes2.cardHeader}>
                                  <h3>Basic Details</h3>
                                </CardHeader>
                                <p className={classes2.divider}>What would you like to call your Record?</p>
                                <CardBody>
                                  <GridContainer justify="center">
                                    <GridItem xs={12} sm={12} md={6} lg={6}>
                                      <CustomInput
                                        error={tokenNameError}
                                        labelText="Record Name *"
                                        id="tokenName"
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          type: "text",
                                          // endAdornment: (
                                          //   <InputAdornment position="end">
                                          //     <PermIdentityIcon className={classes2.inputIconsColor} />
                                          //   </InputAdornment>
                                          // ), 
                                          required: true,
                                          onChange: function (e) {
                                            setTokenName(e.target.value)
                                            setTokenNameError(e.target.value === "" ? true : false)
                                          }, value: tokenName
                                        }}
                                      />
                                    </GridItem>
                                  </GridContainer>

                                  {loading && <LinearProgress />}
                                </CardBody>
                                <CardFooter>
                                  <Grid container className={classes.root} spacing={2}>
                                    <Grid item xs={12}>
                                      <Grid container justify="center" spacing={2}>
                                        <Grid item>
                                          <Button
                                            // disabled={activeStep === 0}
                                            onClick={handleBack}
                                            className={classes.backButton}
                                          > Back</Button>
                                        </Grid>
                                        <Grid item>
                                          <Button variant="contained" color="info" onClick={Basic} type={"submit"}
                                            disabled={false}>
                                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                </CardFooter>
                              </form>
                            </Card>
                          </GridItem>
                        </GridContainer>}
                      {activeStep === 1 && <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={12}>
                          <Card >
                            <form className={classes2.form} style={loading ? {
                              filter: "blur(1px)",
                              "-webkit-filter": "blur(1px)"
                            } : null} onSubmit={SpecificArt}>
                              <CardHeader color="info" className={classes2.cardHeader}>
                                <h3>Record Details</h3>
                              </CardHeader>
                              {category === "RECORD" &&
                                <CardBody>
                                  <GridContainer justify="center">
                                    {fieldContentArray.map((item, key) => (

                                      <GridItem xs={12} sm={12} md={8} lg={8} key={key} >
                                        {item.type === "Image" &&
                                          <FormControl >

                                            <Grid container justify="center" spacing={2} alignContent="stretch"
                                            >
                                              <Grid item alignItems="flex-start">
                                                <InputLabel>
                                                  {item.name}
                                                </InputLabel>
                                              </Grid>
                                              <Grid item alignItems="center">
                                                <img src={item.value} style={{ height: "200px" }}
                                                  className={classes2.imgRounded + " " + classes2.imgFluid} alt={"Image Preview"} />
                                              </Grid>
                                              <Grid item alignItems="flex-end">

                                                <Link onClick={() => {
                                                  removeData(item.name)
                                                }} >  <Delete className={classes2.inputIconsColor} /></Link>

                                              </Grid>
                                            </Grid>


                                            <br></br>


                                          </FormControl>

                                        }
                                        {item.type !== "Image" &&
                                          <CustomInput
                                            labelText={item.name}
                                            id={item.name}
                                            formControlProps={{
                                              fullWidth: true
                                            }}
                                            inputProps={{
                                              type: "text",
                                              endAdornment: (
                                                <InputAdornment position="end" onClick={() => {
                                                  removeData(item.name)
                                                }}>
                                                  <Link>  <Delete className={classes2.inputIconsColor} /></Link>

                                                </InputAdornment>
                                              ),
                                              onChange: function (e) {
                                                addData(item.name, e.target.value)
                                              }, value: item.value, disabled: true
                                            }}
                                          />
                                        }


                                      </GridItem>
                                    ))}
                                    <GridItem xs={12} sm={12} md={8} lg={8}>

                                      {showInput &&
                                        <DataInputFieldGenerator2 addData={addData} setShowInput={setShowInput}
                                        />
                                      }
                                      <Grid container className={classes.root} spacing={2}>
                                        <Grid item xs={12}>
                                          <Grid container justify="center" spacing={2}>
                                            <Grid item>
                                              {!showInput && <Button
                                                // disabled={activeStep === 0}
                                                onClick={() => {
                                                  setShowInput(true)
                                                }}
                                                className={classes.backButton}
                                              > Add Data</Button>}
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>

                                    </GridItem>

                                  </GridContainer>
                                  {loading && <LinearProgress />}
                                </CardBody>
                              }
                              <CardFooter>
                                <Grid container className={classes.root} spacing={2}>
                                  <Grid item xs={12}>
                                    <Grid container justify="center" spacing={2}>
                                      <Grid item>
                                        <Button
                                          // disabled={activeStep === 0}
                                          onClick={handleBack}
                                          className={classes.backButton}
                                        > Back</Button>
                                      </Grid>
                                      <Grid item>
                                        <Button variant="contained" color="info" onClick={SpecificArt} type={"submit"}
                                          disabled={fieldContentArray.length == 0}>
                                          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>



                              </CardFooter>
                            </form>
                          </Card>
                        </GridItem>
                      </GridContainer>
                      }

                    </Typography>
                  </div>
                )}
            </div>
          </div>
        </GridItem>
      </GridContainer>
    </div >
  );
}
