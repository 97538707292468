
import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import Fingerprint from "@material-ui/icons/Fingerprint";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import Grid from '@material-ui/core/Grid';

// import Typography from '@material-ui/core/Typography';
import styles from "assets/jss/material-kit-react/views/loginPage.js";
// import { Link } from "react-router-dom";
// import CreateIcon from '@material-ui/icons/Create';
// import CollectionsIcon from '@material-ui/icons/Collections';
// import MintArtToken from 'views/MintTokenPage/Components/MintArtToken';
// import MintTicketToken from 'views/MintTokenPage/Components/MintTicketToken';
const useStyles = makeStyles(styles);

function MintTokenCompleted(props) {
  const classes = useStyles();
  useEffect(() => {
    const scrollTop=()=>{
      window.scrollTo(0, 0)

    }
    scrollTop()
  }, []);
  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12} >

          <Card >
            <CardHeader color="info" className={classes.cardHeader}>
              <h3>Congrats! You have successfully created your token.</h3>
            </CardHeader>
            {/* <p className={classes2.divider}>Help us understand your token's nature</p> */}
            <CardBody>
              {/* <GridContainer container justify="center" style={{
                justifyContent: 'center'
              }}>
                <GridItem xs={12} sm={12} md={12} >
                  <Button
                    onClick={() => { props.setStep("REALM") }}
                    className={classes.backButton}
                  > Mint More Tokens</Button>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} >
                  <Button variant="contained" color="info"
                    onClick={() => { props.history.push("/") }}
                  >View My Tokens              </Button>
                </GridItem>
              </GridContainer> */}
              <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                  <Grid container justify="center" spacing={2}>
                    <Grid item>
                      <Button
                        onClick={() => { props.setStep("REALM") }}
                        className={classes.backButton}
                      > Mint More Tokens</Button>
                    </Grid>
                    <Grid item>
                      <Button variant="contained" color="info"
                        onClick={() => { props.history.push("/") }}
                      >View My Tokens              </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardBody>
            <CardFooter >



            </CardFooter>

          </Card>

        </GridItem>
      </GridContainer>
    </div>

  );
}

export default MintTokenCompleted