/* eslint-disable */
import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Fingerprint from "@material-ui/icons/Fingerprint";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Typography from '@material-ui/core/Typography';
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import { Link } from "react-router-dom";
import CreateIcon from '@material-ui/icons/Create';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import StoreIcon from '@material-ui/icons/Store';
import ComputerIcon from '@material-ui/icons/Computer';
import CollectionsIcon from '@material-ui/icons/Collections';
import MintArtToken from 'views/MintTokenPage/Components/MintArtToken';
import MintTicketToken from 'views/MintTokenPage/Components/MintTicketToken';
import MintCertificateToken from 'views/MintTokenPage/Components/MintCertificateToken';
import MintRecordToken from 'views/MintTokenPage/Components/MintRecordToken';
import MintBadgeToken from 'views/MintTokenPage/Components/MintBadgeToken';
import MintTokenCompleted from 'views/MintTokenPage/Components/MintTokenCompleted';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import PaymentIcon from '@material-ui/icons/Payment';
import BrushIcon from '@material-ui/icons/Brush';
import WorkIcon from '@material-ui/icons/Work';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import LinkIcon from '@material-ui/icons/Link';
const useStyles = makeStyles(styles);

function MintTokenPage(props) {
  const classes = useStyles();

  const [step, setStep] = useState("REALM")
  // const [step, setStep] = useState("COMPLETED")
  const [realm, setRealm] = useState("")
  const [category, setCategory] = useState("")

  return (
    <div >
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12} >

          {step === "REALM" &&
            <GridContainer justify="center" >
              <GridItem xs={12} sm={12} md={12} lg={12}> <Button disabled onClick={(e) => {
                setStep("REALM")
              }}>Back</Button></GridItem>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <div className={classes.cardHeader}><h3>Pick a Realm!</h3></div>
              </GridItem>
              <GridItem xs={8} sm={8} md={8} lg={8}>
                <Link onClick={(e) => {
                  e.preventDefault()
                  setRealm("DIGITAL")
                  setStep("CATEGORY")
                }} >
                  <CardHeader color="info"
                    className={classes.cardHeader + " btn"}
                    style={{ hover: 50, marginBottom: "20px", marginTop: "20px" }}  >
                    <h3>
                      Digital <ComputerIcon style={{ fontSize: 40 }} /></h3>
                  </CardHeader>
                </Link>
              </GridItem>

              <GridItem xs={8} sm={8} md={8} lg={8}>
                <Link onClick={(e) => {
                  e.preventDefault()
                  setRealm('PHYSICAL')
                  setStep("CATEGORY")
                }} >
                  <CardHeader color="info"
                    className={classes.cardHeader + " btn"}
                    style={{ hover: 50, marginBottom: "20px", marginTop: "20px" }}  >
                    <h3>
                      Physical <StoreIcon style={{ fontSize: 40 }} /></h3>
                  </CardHeader>
                </Link>
              </GridItem>


            </GridContainer>

          }

          {step === "CATEGORY" &&
            <GridContainer justify="center" >
              <GridItem xs={12} sm={12} md={12} lg={12}> <Button onClick={(e) => {
                setStep("REALM")

              }}>Back</Button></GridItem>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <div className={classes.cardHeader}><h3>Pick a Category!</h3></div>
              </GridItem>

              {realm === "DIGITAL" && <GridItem xs={6} sm={6} md={4} lg={3}>
                <Link onClick={(e) => {
                  e.preventDefault()
                  setCategory('CERTIFICATE')
                  setStep("CERTIFICATE")
                }} >
                  <CardHeader color="info"
                    className={classes.cardHeader + " btn"}
                    style={{ hover: 50, marginBottom: "20px", marginTop: "20px" }}  >
                    <h3>
                      Certificates <CardMembershipIcon style={{ fontSize: 40 }} /></h3>
                  </CardHeader>
                </Link>
              </GridItem>}
              {realm === "DIGITAL" && <GridItem xs={6} sm={6} md={4} lg={3}>
                <Link onClick={(e) => {
                  e.preventDefault()
                  setCategory('BADGE')
                  setStep("BADGE")
                }} >
                  <CardHeader color="info"
                    className={classes.cardHeader + " btn"}
                    style={{ hover: 50, marginBottom: "20px", marginTop: "20px" }}  >
                    <h3>
                      Badges <LoyaltyIcon style={{ fontSize: 40 }} /></h3>
                  </CardHeader>
                </Link>
              </GridItem>}
              {realm === "DIGITAL" && <GridItem xs={6} sm={6} md={4} lg={3}>
                <Link onClick={(e) => {
                  e.preventDefault()
                  setCategory('RECORD')
                  setStep("RECORD")
                }} >
                  <CardHeader color="info"
                    className={classes.cardHeader + " btn"}
                    style={{ hover: 50, marginBottom: "20px", marginTop: "20px" }}  >
                    <h3>
                      Records <LibraryBooksIcon style={{ fontSize: 40 }} /></h3>
                  </CardHeader>
                </Link>
              </GridItem>}
              {realm === "DIGITAL" && <GridItem xs={6} sm={6} md={4} lg={3}>

                <CardHeader color="info" disabled
                  className={classes.cardHeader + " btnDisabled"}
                  style={{ hover: 50, marginBottom: "20px", marginTop: "20px" }}  >
                  <h3>
                    Art <BrushIcon style={{ fontSize: 40 }} /></h3>
                </CardHeader>

              </GridItem>}
              {realm === "DIGITAL" && <GridItem xs={6} sm={6} md={4} lg={3}>

                <CardHeader color="info" disabled
                  className={classes.cardHeader + " btnDisabled"}
                  style={{ hover: 50, marginBottom: "20px", marginTop: "20px" }}  >
                  <h3>
                    Tickets <ConfirmationNumberIcon style={{ fontSize: 40 }} /></h3>
                </CardHeader>

              </GridItem>}

              {realm === "DIGITAL" && <GridItem xs={6} sm={6} md={4} lg={3}>

                <CardHeader color="info" disabled
                  className={classes.cardHeader + " btnDisabled"}
                  style={{ hover: 50, marginBottom: "20px", marginTop: "20px" }}  >
                  <h3>
                    Domains <LinkIcon style={{ fontSize: 40 }} /></h3>
                </CardHeader>

              </GridItem>}

              {realm === "DIGITAL" && <GridItem xs={6} sm={6} md={4} lg={3}>

                <CardHeader color="info" disabled
                  className={classes.cardHeader + " btnDisabled"}
                  style={{ hover: 50, marginBottom: "20px", marginTop: "20px" }}  >
                  <h3>
                    Loyalty Points <LoyaltyIcon style={{ fontSize: 40 }} /></h3>
                </CardHeader>

              </GridItem>}
              {/* {realm === "DIGITAL" && <GridItem xs={6} sm={6} md={4} lg={3}>
                <Link onClick={(e) => {
                  e.preventDefault()
                  setCategory('DIGITALART')
                  setStep("ART")
                }} >
                  <CardHeader color="info"
                    className={classes.cardHeader + " btn"}
                    style={{ hover: 50, marginBottom: "20px", marginTop: "20px" }}  >
                    <h3>
                      Art<CollectionsIcon style={{ fontSize: 40 }} /></h3>
                  </CardHeader>
                </Link>
              </GridItem>} */}

              {/* {realm === "DIGITAL" && <GridItem xs={6} sm={6} md={4} lg={3}>
                <Link onClick={(e) => {
                  e.preventDefault()

                  setCategory('TICKET')
                  setStep("TICKET")
                }} >
                  <CardHeader color="info"
                    className={classes.cardHeader + " btn"}
                    style={{ hover: 50, marginBottom: "20px", marginTop: "20px" }}  >
                    <h3>
                      Tickets<CollectionsIcon style={{ fontSize: 40 }} /></h3>
                  </CardHeader>
                </Link>
              </GridItem>} */}
              {/* {realm === "DIGITAL" && <GridItem xs={6} sm={6} md={4} lg={3}>
                <Link onClick={(e) => {
                  e.preventDefault()
                  setCategory('DOMAIN')
                  setStep("DOMAIN")
                }} >
                  <CardHeader color="info"
                    className={classes.cardHeader + " btn"}
                    style={{ hover: 50, marginBottom: "20px", marginTop: "20px" }}  >
                    <h3>
                      Domains<CollectionsIcon style={{ fontSize: 40 }} /></h3>
                  </CardHeader>
                </Link>
              </GridItem>}
              
              {realm === "DIGITAL" && <GridItem xs={6} sm={6} md={4} lg={3}>
                <Link onClick={(e) => {
                  e.preventDefault()

                  setCategory('LOYALTY')
                  setStep("LOYALTY")
                }} >
                  <CardHeader color="info"
                    className={classes.cardHeader + " btn"}
                    style={{ hover: 50, marginBottom: "20px", marginTop: "20px" }}  >
                    <h3>
                    Loyalty Points<CollectionsIcon style={{ fontSize: 40 }} /></h3>
                  </CardHeader>
                </Link>
              </GridItem>} */}

              {realm === "DIGITAL" && <GridItem xs={6} sm={6} md={4} lg={3}>

                <CardHeader color="info" disabled
                  className={classes.cardHeader + " btnDisabled"}
                  style={{ hover: 50, marginBottom: "20px", marginTop: "20px" }}  >
                  <h3>
                    Coupons <CardGiftcardIcon style={{ fontSize: 40 }} /></h3>
                </CardHeader>

              </GridItem>}
              {realm === "DIGITAL" && <GridItem xs={6} sm={6} md={4} lg={3}>

                <CardHeader color="info"
                  className={classes.cardHeader + " btnDisabled"}
                  style={{ hover: 50, marginBottom: "20px", marginTop: "20px" }}  >
                  <h3>
                    Cards <PaymentIcon style={{ fontSize: 40 }} /></h3>
                </CardHeader>

              </GridItem>}

              {realm === "PHYSICAL" && <GridItem xs={6} sm={6} md={4} lg={3}>

                <CardHeader color="info"
                  className={classes.cardHeader + " btnDisabled"}
                  style={{ hover: 50, marginBottom: "20px", marginTop: "20px" }}  >
                  <h3>
                    Collectibles <CollectionsIcon style={{ fontSize: 40 }} /></h3>
                </CardHeader>

              </GridItem>}
              {realm === "PHYSICAL" && <GridItem xs={6} sm={6} md={4} lg={3}>

                <CardHeader color="info"
                  className={classes.cardHeader + " btnDisabled"}
                  style={{ hover: 50, marginBottom: "20px", marginTop: "20px" }}  >
                  <h3>
                    Services <WorkIcon style={{ fontSize: 40 }} /></h3>
                </CardHeader>

              </GridItem>}
              {realm === "PHYSICAL" && <GridItem xs={6} sm={6} md={4} lg={3}>

                <CardHeader color="info"
                  className={classes.cardHeader + " btnDisabled"}
                  style={{ hover: 50, marginBottom: "20px", marginTop: "20px" }}  >
                  <h3>
                    Realestate <LocationCityIcon style={{ fontSize: 40 }} /></h3>
                </CardHeader>

              </GridItem>}
            </GridContainer>
          }


          {step === "ART" &&
            <MintArtToken realm={realm} category={category} setStep={setStep} />
          }
          {step === "TICKET" &&
            <MintTicketToken realm={realm} category={category} setStep={setStep} />
          }
          {step === "CERTIFICATE" &&
            <MintCertificateToken realm={realm} category={category} setStep={setStep} />
          }
          {step === "RECORD" &&
            <MintRecordToken realm={realm} category={category} setStep={setStep} />
          }
          {step === "BADGE" &&
            <MintBadgeToken realm={realm} category={category} setStep={setStep} />
          }
          {step === "COMPLETED" &&
            <MintTokenCompleted setStep={setStep} category={category} history={props.history} />
          }
        </GridItem>
      </GridContainer>
    </div>

  );
}

export default MintTokenPage