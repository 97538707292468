
import React, { useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// import Typography from '@material-ui/core/Typography';
import FileCopyIcon from '@material-ui/icons/FileCopy';
// import AddAlert from "@material-ui/icons/AddAlert";

import AccountBoxIcon from '@material-ui/icons/AccountBox';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
// import Email from "@material-ui/icons/Email";
// import People from "@material-ui/icons/People";
// core components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
// import SnackbarContent from "components/Snackbar/SnackbarContent.js";

import { useSnackbar } from 'notistack';
// import Grid from '@material-ui/core/Grid'; 
import Grid from '@material-ui/core/Grid';
import Dropzone from 'react-dropzone';
import { Link } from "react-router-dom";


import styles from "assets/jss/material-kit-react/views/loginPage.js";
import { getUserSession, decryptSecret } from "services/UserManagement.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Keypair } from "stellar-sdk";
import sha256 from "sha256";

const useStyles = makeStyles(styles);

function BlockchainCredentials(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 400);

  const [user, setUser] = useState(getUserSession());

  const [secretKey, setSecretKey] = useState("");
  // const [encryptyedSecretKey, setEncryptyedSecretKey] = useState(user.encryptedSecret);

  const [publicKey, setPublicKey] = useState(user.publicKey);
  const [password, setPassword] = useState("");

  console.log(user.authType)
  //hover and show
  const [hoverPassword, setHoverPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [linkStyle, setLinkStyle] = useState({ cursor: 'pointer' });
  //Errors 
  const [secretKeyError, setSecretKeyError] = useState(false);
  const [fileError, setFileError] = useState(false)
  const [passwordError, setPasswordError] = useState(false);

  const classes = useStyles();

  // const { ...rest } = props;
  const { enqueueSnackbar } = useSnackbar();

  const copyMessage = (val) => {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }



  return (

    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10} lg={8}>

          <Card className={classes[cardAnimaton]}>
            <form className={classes.form}
              onSubmit={e => {
                e.preventDefault()

                if (password != "") {
                  let pass = password
                  let secret = ""
                  if (user.authType !== "2") {
                    secret = decryptSecret(user.encryptedSecret, sha256(pass))
                  }
                  if (!secret || secret == "" || secret === null || secret === undefined) {
                    enqueueSnackbar("Invalid Credential", { variant: "warning" });
                    setSecretKey("")
                    setPasswordError(true)

                  } else {
                    setSecretKey(secret)
                    setPasswordError(false)

                  }
                } else {
                  setPasswordError(true)
                }


              }}>
              <CardHeader color="info" className={classes.cardHeader}>
                <h3>BLOCKCHAIN CREDENTIALS</h3>
                <h5>powered by Stellar</h5>
              </CardHeader>
              <CardBody>
                <List component="nav" className={classes.root} aria-label="keypair">
                  <ListItem>
                    <ListItemIcon>
                      <AccountBoxIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Public Key"} />
                  </ListItem>
                  <ListItem button onClick={() => {
                    copyMessage(publicKey);
                    enqueueSnackbar("Public Key Copied", { variant: "info" })
                  }}>
                    <ListItemText primary={publicKey} />
                    <FileCopyIcon />
                  </ListItem>
                  <ListItem>
                    {user.authType === "2" &&


                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                      >

                        <Grid item xs={12}>
                          <p className={classes.divider}>Upload your Credential file to view Secret Key</p>
                          <Dropzone onDrop={acceptedFiles => {
                            const reader = new FileReader()
                            reader.addEventListener("load", async () => {
                              // setFile(reader.result)
                              let secret = JSON.parse(atob(reader.result)).secretKey
                              if (Keypair.fromSecret(secret).publicKey() != user.publicKey) {
                                enqueueSnackbar("Credential File doesn't match current account!", { variant: "error" })

                                setFileError(true);

                              } else {
                                setFileError(false);
                                setSecretKey(secret)
                                setSecretKeyError(false);
                              }

                            });
                            reader.readAsText(acceptedFiles[0])
                          }}
                            multiple={false} accept={[".niftron"]}>
                            {({ getRootProps, getInputProps }) => (
                              <Link >
                                <section className={"container"}>
                                  <div {...getRootProps({ className: fileError ? 'dropzone2Error' : 'dropzone2' })}>
                                    <input {...getInputProps()} />
                                    <p>Drop or select your niftron credential file</p>

                                  </div>
                                </section>
                              </Link>
                            )}
                          </Dropzone>

                        </Grid>

                      </Grid>
                    }
                    {user.authType != "2" &&


                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                      >

                        <Grid item xs={12}>

                          <p className={classes.divider}>Decrypt your Secret Key by providing your password</p>
                          <CustomInput
                            error={passwordError}
                            labelText="Password *"
                            id="password"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: !showPassword ? "password" : "text",
                              endAdornment: (
                                <InputAdornment position="end">
                                  {hoverPassword &&
                                    <Icon style={linkStyle} onMouseEnter={() => { setHoverPassword(true) }} onMouseLeave={() => { setHoverPassword(false) }}
                                      className={classes.inputIconsColor} onClick={() => {
                                        setShowPassword(!showPassword)
                                      }}>
                                      {showPassword ? "visibilityoff" : "visibility"}</Icon>
                                  }
                                  {!hoverPassword &&
                                    <Icon style={linkStyle} className={classes.inputIconsColor}
                                      onMouseEnter={() => { setHoverPassword(true) }} onMouseLeave={() => { setHoverPassword(false) }}>
                                      lock</Icon>
                                  }
                                </InputAdornment>
                              ),
                              autoComplete: "off",
                              onChange: function (e) {

                                setPasswordError(e.target.value === "" ? true : false);
                                setPassword(e.target.value)



                              }
                            }}
                          />
                        </Grid>

                        <Grid item xs={12}>  <Button color="info" disabled={secretKey == ""} onClick={e => {
                          e.preventDefault()

                          if (password != "") {
                            let pass = password
                            let secret = ""
                            if (user.authType !== "2") {
                              secret = decryptSecret(user.encryptedSecret, sha256(pass))
                            }
                            if (!secret || secret == "" || secret == null || secret == undefined) {
                              enqueueSnackbar("Invalid Credential", { variant: "warning" });
                              setSecretKey("")
                              setPasswordError(true)

                            } else {
                              setSecretKey(secret)
                              setPasswordError(false)

                            }
                          } else {
                            setPasswordError(true)
                          }


                        }}>Decrypt</Button>

                        </Grid></Grid>



                    }
                  </ListItem>
                  {secretKey != "" &&
                    <> <ListItem>
                      <ListItemIcon>
                        <VpnKeyIcon />
                      </ListItemIcon>
                      <ListItemText primary={"Secret Key"} />
                    </ListItem>
                      <ListItem button disabled={secretKey == ""} onClick={() => {
                        copyMessage(secretKey);
                        enqueueSnackbar("Secret Key Copied", { variant: "info" })
                      }}>
                        <ListItemText primary={secretKey} /> <FileCopyIcon />
                      </ListItem></>
                  }

                </List>
              </CardBody>

              <CardFooter className={classes.cardFooter}>

                <Button  color="info" size="lg" onClick={() => {
                  var FileSaver = require("file-saver");
                  var credObj = { secretKey }
                  // var blob = new Blob(
                  //   [
                  //     "PublicKey: " +
                  //     publicKey +
                  //     " \nSecretKey: " +
                  //     secretKey
                  //   ],
                  //   { type: "text/plain;charset=utf-8" }
                  // );
                  var blob = new Blob(
                    [
                      btoa(JSON.stringify(credObj))
                    ],
                    { type: "text/plain;charset=utf-8" }
                  );
                  FileSaver.saveAs(blob, `${user.alias}_credential.niftron`);

                }} disabled={secretKey == ""} >
                  Export to file </Button>
              </CardFooter>
            </form>
          </Card>


        </GridItem>
      </GridContainer>
    </div >
  );
}

export default BlockchainCredentials