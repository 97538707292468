import {
    ADD_STELLAR_URL,ADD_LOADING_MESSAGE
} from '../constants/ActionTypes'
import {
    niftronUserLambda, niftronTokenLambda,
    StellarUrl, StellarUrlTest, Version,
    NiftronIssuerPublicKey,
    NiftronDistributorPublicKey
} from "variables/constants"

const initialState = {
    niftronUserLambda, niftronTokenLambda,
    StellarUrl, StellarUrlTest, Version,
    NiftronIssuerPublicKey,
    NiftronDistributorPublicKey, LoadingMessage: "please remain patient"
}

export default function variables(state = initialState, action) {
    switch (action.type) {
        case ADD_STELLAR_URL:
            state.StellarUrl = action.text
            return state
        case ADD_LOADING_MESSAGE:
            state.LoadingMessage = action.text
            return state
        default:
            return state
    }
}
