
import React, { useEffect, useState } from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import TokenMorpher from "views/VaultList/Components/TokenMorpher"
import DummyToken from "views/VaultList/Components/DummyToken"
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { getUserSession } from "services/UserManagement";
import { searchToken } from "services/TokenManagement";
// import Paginations from "components/Pagination/Pagination.js";
import Pagination from '@material-ui/lab/Pagination';
import Grid from '@material-ui/core/Grid';
import { withRouter, Link } from "react-router-dom";

const useStyles = makeStyles(styles);

function CreationList(props) {
  const classes = useStyles();
  const [user, setUser] = useState(getUserSession())
  const [tokens, setTokens] = useState([])
  const [loading, setloading] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(8)


  const [tradable, setTradable] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setloading(true)

      //non tradable
      const key = user.accounts[1].publicKey

      const result = await searchToken(key, "issuer", limit, page)
      if (result != null) {
        if (result.data && result.data.length > 0) {
          setTokens(result.data)
          setPageCount(result.pageCount)
        }
        setloading(false)

      }

    }
    fetchData()
  }, [page]);
  return (
    <div>
      {loading &&
        <GridContainer justify="center" style={{ minHeight: 500 }}>
          {[0, 1, 2, 3].map(i =>
            <GridItem key={i} xs={12} sm={6} md={4} lg={3} className={"loadingCard"}>
              <DummyToken />
            </GridItem>
          )}
        </GridContainer>
      }
      {!loading && tokens.length > 0 &&
        <GridContainer justify="center" style={{ minHeight: 500 }} >
          {tokens.map((item, index) => (
            <GridItem key={index} xs={12} sm={6} md={4} lg={3} >
              <Link to={"/token/" + item._id}>
                <TokenMorpher item={item} />
              </Link>
            </GridItem>
          ))}
        </GridContainer>
      }
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Pagination count={pageCount} color="info"
            style={{ float: "right" }}
            onChange={(e, number) => {
              //console.log(number)
              setPage(number)
            }} />
        </GridItem>
      </GridContainer>

    </div>
  );
}

export default withRouter(CreationList)