/* eslint-disable */
import sha256 from "sha256";

export function BuildNiftronId(
  tokenName,
  tokenType,
  tradable,
  transferable,
  authorizable ,
  tokenData
) {
  try {
    if (tokenType === null) {
      throw new Error("Token type cannot be null");
    }
    if (tokenName === null || tokenName === "") {
      throw new Error("Token name cannot be null or empty");
    }
    if (tokenData === null || tokenData === "") {
      throw new Error("Token data cannot be null or empty");
    }
    let firstByte;
    switch (tokenType) {
      case "NFT":
        firstByte = "N";
        break;
      case "SFT":
        firstByte = "S";
        break;
    }
    
    const secondByte = tradable ? "T" : "N";
    const thirdByte = transferable ? "T" : "N";
    const forthByte = authorizable ? "A" : "N";

    const tokenNameHash = sha256(tokenName).toUpperCase();
    const nextOneBytes =
      tokenNameHash.substring(0, 1)
    const dataHash = tokenData.toUpperCase();
    const lastSixBytes =
      dataHash.substring(0, 3) + dataHash.substring(dataHash.length - 3);

    const id = {
      id:
        firstByte +
        secondByte +
        thirdByte +
        forthByte +
        nextOneBytes +
        "N" +
        lastSixBytes,
      redeemId:
        firstByte +
        secondByte +
        thirdByte +
        forthByte +
        nextOneBytes +
        "R" +
        lastSixBytes,
      version: "1.0",
    };
    return id;
  } catch (err) {
    //console.log(err);
    return null;
  }
}
